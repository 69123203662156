import type { TrackingDriver } from '@/tracking/contracts/TrackingDriver'
import { ApiTrackingDriver } from '@/tracking/drivers/ApiTrackingDriver'
import { useHttp } from '@/composables/useHttp'
import { MockTrackingDriver } from '@/tracking/drivers/MockTrackingDriver'

let driver: TrackingDriver | null = null

function createTracking(): TrackingDriver {
  if (import.meta.env.SSR) {
    driver = new MockTrackingDriver()
  } else {
    driver = new ApiTrackingDriver(useHttp())
  }

  return driver
}

function useTracking(): TrackingDriver {
  if (null === driver) {
    return createTracking()
  }

  return driver
}

export { useTracking, createTracking }