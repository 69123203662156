import { defineStore } from "pinia"
import { ref } from "vue"

export const useTrackingStore = defineStore('tracking', () => {
  const deviceToken = ref<string>()
  const sessionToken = ref<string>()

  const setDeviceToken = (token: string) => deviceToken.value = token
  const setSessionToken = (token: string) => sessionToken.value = token

  return {
    deviceToken,
    sessionToken,
    setDeviceToken,
    setSessionToken,
  }
}, { persist: true })