import { type Ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useLocalizationStore } from '@/stores/useLocalizationStore'

/**
 * Returns the reactive value for current locale
 * The value is resolved during route resolution and should only be `null` on unresolved routes (404)
 */
export const getLocale = (): Ref<string | null> => {
  const { currentLocale } = storeToRefs(useLocalizationStore())
  return currentLocale
}
