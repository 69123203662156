import type { RouteRecordLocalized } from '@/router/types/RouteRecordLocalized'

export const getLocalizedRoutes = (): RouteRecordLocalized[] => {
  return [
    {
      path: '/',
      component: () => import('@/pages/home/Home.vue'),
      name: 'home',
    },
    {
      path: '/privacy-statement',
      component: () => import('@/pages/Privacy.vue'),
      name: 'privacy',
    },
    {
      path: '/terms-and-conditions',
      component: () => import('@/pages/Terms.vue'),
      name: 'terms',
    },
    {
      path: '/appstore-redirect',
      component: () => import('@/pages/AppstoreRedirect.vue'),
      name: 'AppstoreRedirect',
    },
    {
      path: '/cookie-declaration',
      component: () => import('@/pages/Cookie.vue'),
      name: 'cookie',
    },
    {
      path: '/customer-support',
      component: () => import('@/pages/CustomerSupport.vue'),
      name: 'customer-support',
    },
    {
      path: '/login',
      component: () => import('@/pages/account/Login.vue'),
      name: 'login',
    },
    {
      path: '/register',
      component: () => import('@/pages/account/Register.vue'),
      name: 'register',
    },
    {
      path: '/account/recover',
      component: () => import('@/pages/account/Recover.vue'),
      name: 'recover',
    },
    {
      path: '/account/verified',
      component: () => import('@/pages/account/Verified.vue'),
      name: 'verified',
    },
    {
      path: '/account',
      component: () => import('@/pages/account/Account.vue'),
      name: 'customer-account',
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '/account/vouchers',
          component: () => import('@/pages/account/vouchers/Vouchers.vue'),
          name: 'customer-account-vouchers',
        },
        {
          path: '/account/orders',
          component: () => import('@/pages/account/orders/Orders.vue'),
          name: 'customer-account-orders',
        },
        {
          path: '/account/personal-details',
          component: () => import('@/pages/account/personal-details/PersonalDetails.vue'),
          name: 'customer-account-personal-details',
        },
        {
          path: '/account/settings',
          component: () => import('@/pages/account/settings/Settings.vue'),
          name: 'customer-account-settings',
        },
      ],
    },
    {
      path: '/not-found',
      name: 'not-found',
      component: () => import('@/pages/404.vue'),
    },
    {
      path: '/all-categories',
      countryRoutePath: {
        en: '/all-categories',
        nl: '/alle-categorieen',
      },
      component: () => import('@/pages/AllCategories.vue'),
      name: 'all-categories',
    },
    {
      path: '/all-cities',
      countryRoutePath: {
        nl: '/alle-steden',
        en: '/all-cities',
      },
      component: () => import('@/pages/AllCities.vue'),
      name: 'all-cities',
    },
    {
      path: '/checkout',
      component: () => import('@/pages/checkout/Checkout.vue'),
      name: 'checkout',
    },
    {
      path: '/thanks/:orderUuid',
      component: () => import('@/pages/thanks/Thanks.vue'),
      name: 'thanks',
    },
    {
      path: '/marketplace',
      component: () => import('@/pages/marketplace/Marketplace.vue'),
      name: 'marketplace',
      countryRoutePath: {
        nl: '/marktplaats',
        en: '/marketplace',
      },
    },
    {
      path: '/:country?/:city/restaurants/:slug/:onLocationId?/:direct?',
      component: () => import('@/pages/establishment/Establishment.vue'),
      name: 'establishment',
      meta: {
        crumbs: [],
      },
    },
    {
      path: '/:country?/:city/restaurants/:slug/review/:review',
      component: () => import('@/pages/establishment/Establishment.vue'),
      name: 'establishment-add-review',
    },
    {
      path: '/:slug([^/]+)',
      component: () => import('@/pages/Slug.vue'),
      name: 'slug',
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/pages/404.vue'),
      name: 'not-found',
    },
  ]
}
