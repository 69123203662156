import type { Router } from 'vue-router'
import { useLocalizationStore } from '@/stores/useLocalizationStore'
import { setI18nLocale } from '@/composables/I18n'

export const registerWebLocalization = (router: Router) => {
  router.beforeEach((to, from, next) => {
    if (to.name === 'not-found') {
      return next()
    }

    const {setCurrentLocale, setCurrentCountry} = useLocalizationStore()
    const {locale, country} = to.meta

    if (typeof locale !== 'string' || typeof country !== 'string') {
      console.error('Route is missing required metadata:', to.path)
      return next({name: 'not-found'}) // Redirect to a 404 route
    }

    setCurrentCountry(country)
    setCurrentLocale(locale)

    setI18nLocale(locale)

    next()
  })
}