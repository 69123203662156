import { createMemoryHistory, createRouter, createWebHistory, type Router } from 'vue-router'
import { getActiveConfiguration } from '@/config/domains/utils/getActiveConfiguration'
import { generateRoutes } from '@/router/utils/generateRoutes'
import type { App } from 'vue'
import type { SSRContext } from '@/types/ssr/SSRContext'
import { scrollBehavior } from '@/router/scrollBehavior'
import { registerRequiresAuth } from '@/router/navigationGuards/requiresAuth'
import { registerWebLocalization } from '@/router/navigationGuards/registerWebLocalization'
import { getLocalizedRoutes } from '@/router/routes'

export const createLocalizedWebRouter = (app: App, ssrContext?: SSRContext): Router => {
  const config = getActiveConfiguration(ssrContext)
  const routes = generateRoutes(config, getLocalizedRoutes())

  const router = createRouter({
    history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
    routes,
    scrollBehavior,
  })

  registerWebLocalization(router)
  registerRequiresAuth(router)

  return router
}