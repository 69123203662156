import { createI18n, type I18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

const i18n = createI18n({
  locale: 'nl',
  fallbackLocale: 'en',
  legacy: false,
  globalInjection: false,
  messages,
})

export function useI18n(): I18n {
  return i18n
}

export const setI18nLocale = (locale: string): void => {
  i18n.global.locale.value = locale
}