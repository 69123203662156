import type { DomainConfiguration } from '@/router/types/DomainConfiguration'
import type { RouteRecordRaw } from 'vue-router'
import type { RouteRecordLocalized } from '@/router/types/RouteRecordLocalized'

export const generateRoutes = (config: DomainConfiguration, localizedRoutes: RouteRecordLocalized[]): RouteRecordRaw[] => {
  // Which paths we've added, to prevent adding duplicate path entries
  const addedPaths = new Set<string>()

  // The routes, build for each locale
  const routes: RouteRecordRaw[] = []

  localizedRoutes.forEach((route: RouteRecordLocalized) => {
    if (route.children) {
      const children: RouteRecordRaw[] = []

      for (const childRoute of route.children) {
        config.supportedLocales.forEach(locale => {
          children.push({
            ...childRoute,
            name: `${locale}__${String(childRoute.name)}`,
            meta: {
              ...(childRoute.meta || {}),
              locale,
              country: config.country,
            },
          })
        })
      }

      route.children = children
    }
    config.supportedLocales.forEach(locale => {
      // Get the base path, relative to the locale (from domainConfig)
      const basePath = `${config.basePath}${locale === config.defaultLocale ? '' : `/${locale}`}`

      if (typeof route.countryRoutePath !== 'undefined') {
        const countryRoutePath = route.countryRoutePath.hasOwnProperty(locale)
          ? route.countryRoutePath[locale]
          : route.path

        const path = `${basePath}${countryRoutePath}`.replace(/\/$/, '').replace(/\/\//g, '/')

        if (addedPaths.has(path)) {
          throw new Error('Duplicate route path detected.')
        }

        routes.push({
          ...route,
          path: path,
          name: `${locale}__${String(route.name)}`,
          meta: {
            ...(route.meta || {}),
            locale,
            country: config.country,
          },
        })
        addedPaths.add(path)
        return
      }

      // Build the full path from default route.path and normalize the path to remove redundant slashes
      const localizedPath = `${basePath}${route.path}`.replace(/\/$/, '').replace(/\/\//g, '/')

      // Prevent route path collision
      if (addedPaths.has(localizedPath)) {
        throw new Error('Duplicate route path detected.')
      }

      routes.push({
        ...route,
        path: localizedPath,
        // Route name is either default (based on current domain) or localized prefixed
        name: `${locale}__${String(route.name)}`,
        meta: {
          ...(route.meta || {}),
          locale,
          country: config.country,
        },
      })

      addedPaths.add(localizedPath)
    })
  })

  return routes
}


