import App from './App.vue'
import { createApp as createCsrApp, createSSRApp } from 'vue'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { useI18n } from '@/composables/I18n'
import taptic from '@/plugins/directives/taptic'
import { createRouter } from '@/composables/useRouter'
import { createHttp } from '@/composables/useHttp'
import type { SSRContext } from '@/types/ssr/SSRContext'
import { PlatformPlugin } from '@/plugins/platformPlugin'
import { createPreferencesStorage } from '@/stores/storage/PreferencesStorage'

export async function createApp(ssrContext?: SSRContext) {
  const app = import.meta.env.SSR ? createSSRApp(App) : createCsrApp(App)

  const i18n = useI18n()
  app.use(i18n)

  app.directive('taptic', taptic)

  const pinia = createPinia()

  if (typeof window !== 'undefined') { // Is not SSR
    if (__CAPACITOR__) {
      const storage = createPreferencesStorage()

      pinia.use(createPersistedState({
        storage: storage,
      }))

      await storage.init()
    } else {
      pinia.use(createPersistedState({
        storage: localStorage,
      }))
    }
  }

  app.use(pinia)

  const router = createRouter(app, ssrContext)

  app.use(router)
  app.use(PlatformPlugin)

  createHttp(router)

  return { app, router, pinia }
}