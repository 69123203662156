import { useAuthStore } from "@/stores/useAuthStore"
import { useLocalizedRouter } from "@/composables/localizedRouter"
import type { Router } from "vue-router"

export const registerRequiresAuth = (router: Router) => {
  router.beforeEach((to) => {
    const authStore = useAuthStore()
    const { localizedRoute } = useLocalizedRouter()

    const { requiresAuth } = to.meta

    if (requiresAuth && !authStore.isAuthenticated) {
      return localizedRoute({ name: `login` })
    }

    return true
  })
}