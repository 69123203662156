import Cookies from 'js-cookie'
import type { BistrooCookie } from '@/types/BistrooCookie'
import { useSafeSSRContext } from '@/utils/useSafeSSRContext'

export const useCookies = () => {
  const ctx = import.meta.env.SSR ? useSafeSSRContext() : null

  const setCookie = <T extends keyof BistrooCookie>(name: T, value: BistrooCookie[T]): void => {
    if (ctx) {
      ctx.setCookie(name, value)
    } else {
      Cookies.set(name, value as string)
    }
  }

  const getCookie = <T extends keyof BistrooCookie>(name: T): BistrooCookie[T] | undefined => {
    if (ctx) {
      return ctx.getCookie(name)
    }

    const value = Cookies.get(name)
    if (value !== undefined) {
      return value as BistrooCookie[T]
    }

    return undefined
  }

  return {setCookie, getCookie}
}