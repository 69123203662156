import {createLocalizedWebRouter} from '@/router/createLocalizedWebRouter'
import type {App} from 'vue'
import type {SSRContext} from '@/types/ssr/SSRContext'
import {createAppRouter} from '@/router/createAppRouter'

export const createRouter = (app: App, ssrContext?: SSRContext) => {
  if (__CAPACITOR__) {
    return createAppRouter()
  } else {
    return createLocalizedWebRouter(app, ssrContext)
  }
}