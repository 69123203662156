import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Location } from '@/types/Location'
import type { Distribution } from '@/enums/Distribution'

export const useSessionStore = defineStore('session', () => {
  const onLocationIdentifier = ref<string | null>(null)
  const location = ref<Location | null>(null)
  const distribution = ref<Distribution | null>(null)
  const ageCheckApproved = ref<boolean>(false)
  const gclid = ref<string | null>(null)
  const tracking = ref<{} | null>(null)

  const setOnLocationIdentifier = (value: string | null) => {
    onLocationIdentifier.value = value
  }

  const setLocation = (value) => {
    location.value = new Location(value)
  }

  const clearLocation = () => {
    location.value = null
  }

  const setDistribution = (value: Distribution | null): void => {
    distribution.value = value
  }

  const setAgeCheckApproved = (value: boolean): void => {
    ageCheckApproved.value = value
  }

  const setGclid = (value: string) => gclid.value = value

  const setTracking = (value: {}) => tracking.value = value

  return {
    setOnLocationIdentifier,
    onLocationIdentifier,

    location,
    setLocation,
    clearLocation,

    setDistribution,
    distribution,

    setAgeCheckApproved,
    ageCheckApproved,

    gclid,
    setGclid,

    tracking,
    setTracking,
  }
}, {
  persist: {
    pick: [ 'distribution', 'location', 'onLocationIdentifier' ],
  },
})