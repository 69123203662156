import type { DomainConfiguration } from '@/router/types/DomainConfiguration'

type DomainDefinition = { [domain: string]: { configurations: DomainConfiguration[] } }

export const getDomainConfig = (): DomainDefinition => {
  const env = import.meta.env ? import.meta.env.VITE_BUILD_ENV : 'production'

  if (env === 'staging') {
    return {
      'staging.bistroo.nl': {
        configurations: [
          {basePath: '', defaultLocale: 'nl', supportedLocales: ['nl', 'en'], country: 'nl'},
        ],
      },
      'staging.bistroo.com': {
        configurations: [
          {basePath: '/be', defaultLocale: 'nl', supportedLocales: ['nl', 'en'], country: 'be'},
        ],
      },
    } as const
  }

  return {
    'www.bistroo.nl': {
      configurations: [
        {basePath: '', defaultLocale: 'nl', supportedLocales: ['nl', 'en'], country: 'nl'},
      ],
    },
    'www.bistroo.com': {
      configurations: [
        {basePath: '/be', defaultLocale: 'nl', supportedLocales: ['nl', 'en'], country: 'be'},
      ],
    },
  } as const
}