import { useSSRContext } from 'vue'
import type { SSRContext } from '@/types/ssr/SSRContext'

export function getRequestProtocol() {
  if (import.meta.env.SSR) {
    const ctx = <SSRContext>useSSRContext()
    return ctx.protocol
  }

  return new URL(window.location.href).protocol
}
