import type { DomainConfiguration } from '@/router/types/DomainConfiguration'
import { getDomainConfig } from '@/config/domains/domainConfig'
import type { SSRContext } from '@/types/ssr/SSRContext'

export const getActiveConfiguration = (ssrContext?: SSRContext): DomainConfiguration => {
  const domainConfig = getDomainConfig()

  const url = new URL(ssrContext ? ssrContext.url : window.location.href)
  const hostname: string = url.hostname
  const pathname: string = url.pathname

  if (hostname === 'localhost' || hostname === '[::1]') {
    return domainConfig['www.bistroo.nl'].configurations[0]
  }

  if (!Object.keys(domainConfig).includes(hostname)) {
    throw new Error(`${hostname} is not a valid domain`)
  }

  const activeConfig = domainConfig[hostname]

  // Sort configurations by basePath length in descending order
  const sortedConfigurations = activeConfig.configurations.sort(
    (a, b) => b.basePath.length - a.basePath.length,
  )

  for (const config of sortedConfigurations) {
    if (pathname === config.basePath || pathname.startsWith(`${config.basePath}/`)) {
      return config
    }
  }

  throw new Error(`No valid configuration found for ${pathname}`)
}

