import { defineStore } from 'pinia'
import { ref } from 'vue'

import UserResource from '@/resources/UserResource'
import { Address } from '@/http/models/Address'

export const useUserStore = defineStore('user', () => {
  const user = ref<UserResource | null>(null)
  const addresses = ref<Address[]>([])
  const hasCreatedAccountFromGuestSession = ref<boolean>(false)

  const setUser = (value) => {
    user.value = new UserResource(value)
  }

  const clearUser = () => {
    user.value = null
  }

  const setAddresses = (values) => {
    addresses.value = values.map((address) => new Address(address))
  }

  const setUserHasCreatedAccountFromGuestSession = (value: boolean) => {
    hasCreatedAccountFromGuestSession.value = value
  }

  return {
    user,
    addresses,
    hasCreatedAccountFromGuestSession,

    setUser,
    clearUser,
    setAddresses,
    setUserHasCreatedAccountFromGuestSession,
  }
}, { persist: true })