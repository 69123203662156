import { type RouteLocationNamedRaw, useRouter } from 'vue-router'
import { getLocale } from "@/router/utils/getLocale"

export function useLocalizedRouter() {
  const router = useRouter()
  const locale = getLocale()

  function localizedRoute(to: RouteLocationNamedRaw) {
    if (__CAPACITOR__) {
      return router.resolve(to)
    }

    to.name = `${locale.value}__${to.name as string}`

    return router.resolve(to)
  }

  return {
    localizedRoute,
  }
}
