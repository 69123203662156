<template>
  <RouterView/>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'
import { useHead } from '@unhead/vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
//import { useExperiments } from '@/composables/experiments'
import { getRequestHostname } from '@/utils/getRequestHostname'
import { getCountry } from '@/router/utils/getCountry'
import { useSessionStore } from '@/stores/useSessionStore'
import { createTracking } from '@/composables/useTracking'
import Cookies from 'js-cookie'
import { useAuthStore } from '@/stores/useAuthStore'
import { useUserStore } from '@/stores/useUserStore'
import { useAuthHttp } from '@/http/authHttp'
import { getConfigurationForCountry } from '@/config/domains/utils/getConfigurationForCountry'
import { getRequestProtocol } from '@/utils/getRequestProtocol'
import { useCookies } from '@/composables/useCookies'
import { storeToRefs } from 'pinia'
import { Distribution } from "@/enums/Distribution"

const route = useRoute()
const router = useRouter()

const { t, locale } = useI18n()
const host = getRequestHostname()
const protocol = getRequestProtocol()
const country = getCountry()
const authStore = useAuthStore()
const userStore = useUserStore()
const { profile } = useAuthHttp()

if (!__CAPACITOR__) {
  const currentRoutes = computed(() => {
    return getConfigurationForCountry(country.value).supportedLocales
        .map((locale) => {
          const strippedRouteName = String(route.name).split('__')[1]
          const { href } = router.resolve({ name: `${locale}__${strippedRouteName}` })
          return {
            rel: 'alternate',
            hreflang: getCountry().value === locale ? 'x-default' : `${getCountry().value}-${locale}`,
            href: `${protocol}://${host}${href}`,
          }
        })
  })

  useHead({
    title: t('titles.default'),
    htmlAttrs: {
      lang: locale.value,
    },
    meta: [
      {
        name: 'title',
        content: t('meta.title'),
      },
      {
        name: 'description',
        content: t('meta.description'),
      },
      {
        name: 'google',
        content: 'notranslate',
      },
      {
        property: 'og:title',
        content: t('titles.default'),
      },
      {
        property: 'twitter:title',
        content: t('titles.default'),
      },
      ...((import.meta.env.VITE_BUILD_ENV !== 'production') ? [
        {
          name: 'robots',
          content: 'noindex',
        },
      ] : []),
    ],
    link: [ ...currentRoutes.value ],
    script: [
      ...(!__CAPACITOR__ && import.meta.env.PROD ? [
        {
          src: 'https://consent.cookiebot.com/uc.js',
          'data-cbid': 'dda8dd44-585b-4b2a-999b-817d41687ad6',
          id: 'Cookiebot',
          type: 'text/javascript',
          defer: true,
        },
      ] : []),
    ],
  })
}

const setAppHeightVariable = () => {
  const safeInsetTop = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sat')) || 0
  const safeInsetBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sab')) || 0

  const appHeight = window.innerHeight - safeInsetBottom - safeInsetTop

  document.documentElement.style.setProperty('--app-height', `${appHeight}px`)
}

const setScrollbarWidthVariable = () => {
  // Creating invisible container
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll' // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps
  document.body.appendChild(outer)

  // Creating inner element and placing it in the container
  const inner = document.createElement('div')
  outer.appendChild(inner)

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth)

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer)

  document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`)
}

const setRootVariables = () => {
  setScrollbarWidthVariable()
  setAppHeightVariable()
}

// onServerPrefetch(async () => {
//   const { startExperiments } = useExperiments()
//   await startExperiments()
// })

const { setCookie } = useCookies()
const sessionStore = useSessionStore()
const { distribution } = storeToRefs(useSessionStore())

watch(distribution, (value: Distribution) => {
  if (__CAPACITOR__) {
    return
  }

  if (value !== Distribution.OnLocation) {
    setCookie('bist_distribution', distribution.value.toString())
  }
})

onMounted(async () => {
  setRootVariables()

  window.addEventListener('orientationchange', setRootVariables)
  window.addEventListener('resize', setRootVariables)

  if (route.query.gclid) {
    sessionStore.setGclid(route.query.gclid as string)
  }

  const tracking = createTracking()

  tracking.start()

  // Necessary store migration
  migrateAuthStore()
})

async function migrateAuthStore() {
  try {
    const rawCookie = Cookies.get('token')

    if (rawCookie) {
      const token = JSON.parse(atob(rawCookie).toString())

      authStore.setToken(token)

      const user = await profile()

      userStore.setUser({ id: user.id, ...user.attributes })
    }
  } catch (e) {
    console.log(e)
  } finally {
    Cookies.remove('token')
  }
}
</script>

<style lang="scss">
@import "@/assets/css/main.scss";

</style>
