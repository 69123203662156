import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLocalizationStore = defineStore('localization', () => {
  const currentLocale = ref<string>('nl') // Default NL
  const currentCountry = ref<string>('nl') // Default NL

  const getCurrentLocale = (): string => currentLocale.value
  const getCurrentCountry = (): string => currentCountry.value

  const setCurrentLocale = (value: string): void => {
    currentLocale.value = value
  }

  const setCurrentCountry = (value: string): void => {
    currentCountry.value = value
  }

  return {
    currentCountry,
    currentLocale,
    getCurrentLocale,
    getCurrentCountry,
    setCurrentLocale,
    setCurrentCountry,
  }
}, { persist: __CAPACITOR__ })