class UserResource {
  constructor(user) {
    if (!user) return
    if ('raw' in user) user = user.raw

    this.id = user.id || null
    this.name = user.name || ''
    this.phone = user.phone || user.phone_number || ''
    this.email = user.email || ''
    this.dateOfBirth = user.date_of_birth || ''
    this.instructions = user.instructions || ''
    this.country = user.country

    this.raw = user
  }
}

export default UserResource
