import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useAuthStore = defineStore('auth', () => {
  const _token = ref<{ access_token: string, expires_in: number } | null>(null)

  const setToken = (value: { access_token: string, expires_in: number } | null) => {
    _token.value = value
  }

  const isAuthenticated = computed<boolean>(() => _token.value !== null)
  const accessToken = computed<string>(() => _token.value.access_token)

  return {
    accessToken,
    setToken,
    isAuthenticated,

    // persisted state
    _token,
  }
}, { persist: true })