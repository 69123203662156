import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'

export const useModalStore = defineStore('modal', () => {
  const state = reactive({
    title: null,
    message: null,
    callbacks: null,
    closable: true,
    data: null,
    dataTestId: null,
  })

  const show = ({ title = null, message, callbacks = null, closable = true, data = null, dataTestId = null }) => {
    state.title = title
    state.message = message
    state.callbacks = typeof callbacks === 'function' ? [ callbacks ] : callbacks
    state.closable = closable
    state.data = data
    state.dataTestId = dataTestId
  }

  const reset = () => {
    state.title = null
    state.message = null
    state.callbacks = null
    state.closable = true
    state.data = null
    state.dataTestId = null
  }

  const callCallback = (index: number) => {
    if (index in state.callbacks && typeof state.callbacks[index].action === 'function') {
      state.callbacks[index].action()
    }

    reset()
  }

  const title = computed(() => state.title)
  const message = computed(() => state.message)
  const callbacks = computed(() => state.callbacks)
  const closable = computed(() => state.closable)
  const data = computed(() => state.data)
  const dataTestId = computed(() => state.dataTestId)

  return {
    show,
    reset,
    callCallback,
    title,
    message,
    callbacks,
    closable,
    data,
    dataTestId,
  }
})