import { useHttp } from "@/composables/useHttp"
import { formatISO } from "date-fns"
import { parsePhoneNumber } from "libphonenumber-js/max"

const CLIENT_ID = 3

export class LoginError extends Error {
  message: string
  code: string

  static make(code: string, message: string) {
    const obj = new LoginError()
    obj.message = message
    obj.code = code

    return obj
  }
}

export function useAuthHttp() {
  const http = useHttp()

  async function login(email: string, password: string) {
    try {
      const response = await http.post('oauth/token', {
        client_id: CLIENT_ID,
        grant_type: 'password',
        username: email,
        password,
      })

      return response.data
    } catch (error) {
      throw LoginError.make(error.response.data.error, error.response.data.message)
    }
  }

  async function profile() {
    const response = await http.get('profile')

    return response.data.data
  }

  async function logout() {
    await http.post('auth/logout')
  }

  async function deleteProfile() {
    await http.delete('profile')
  }

  async function register(
    { name, phoneNumber, email, password, passwordConfirmation },
  ) {
    const response = await http.post('auth/register', {
      name,
      email,
      password,
      password_confirmation: passwordConfirmation,
      phone_number: phoneNumber,
    })

    return response.data
  }

  async function resendEmail({ email }) {
    const response = await http.post('auth/email/request-verification', {
      email,
    })

    return response.data
  }

  async function recover({ email }) {
    const response = await http.post('auth/password/forgotten', {
      email,
    })

    return response.data
  }

  async function recoverPassword({ token, email, password, passwordConfirmation }) {
    const response = await http.post('auth/password/reset', {
      token,
      email,
      password: password,
      password_confirmation: passwordConfirmation,
    })

    return response.data
  }

  async function updateProfile({ name, phoneNumber, email, dateOfBirth }) {
    const response = await http.put('profile', {
      name,
      phone_number: phoneNumber,
      phone_number_country: parsePhoneNumber(phoneNumber).country,
      email,
      date_of_birth: dateOfBirth
        ? formatISO(dateOfBirth, { representation: 'date' })
        : null,
    })

    return response.data
  }

  async function updatePassword({ currentPassword, newPassword, newPasswordConfirmation }) {
    const response = await http.put('profile/password', {
      current_password: currentPassword,
      new_password: newPassword,
      new_password_confirmation: newPasswordConfirmation,
    })

    return response.data
  }

  async function updateAddress({
                                 id,
                                 title,
                                 street,
                                 zipcode,
                                 houseNumber,
                                 coordinates: { lat, lng },
                                 city,
                                 country,
                               }) {
    const response = await http.put(`profile/addresses/${id}`, {
      title,
      street_number: houseNumber,
      street_name: street,
      city,
      lat,
      lng,
      zipcode,
      country,
    })

    return response.data
  }

  async function addresses() {
    const response = await http.get('profile/addresses')

    return response.data.data
  }

  async function createAddress({
                                 title,
                                 street,
                                 zipcode,
                                 houseNumber,
                                 coordinates: { lat, lng },
                                 city,
                                 countryCode = 'NL',
                               }) {
    const response = await http.put(`profile/addresses`, {
      title,
      street_number: houseNumber,
      street_name: street,
      city,
      lat,
      lng,
      zipcode,
      country_code: countryCode,
    })

    return response.data
  }

  async function deleteAddress(id: any) {
    const response = await http.delete(`profile/addresses/${id}`)

    return response.data
  }

  async function getOrders(page: number, perPage: number) {
    const response = await http.get(`profile/orders?page=${page}&per_page=${perPage}`)

    return response.data
  }

  async function getOrderById(id: string) {
    const response = await http.get(`profile/orders/${id}`)

    return response.data
  }

  return {
    login,
    logout,
    register,
    profile,
    deleteProfile,
    resendEmail,
    recover,
    recoverPassword,
    updateProfile,
    updatePassword,
    addresses,
    updateAddress,
    createAddress,
    deleteAddress,
    getOrders,
    getOrderById,
  }
}