class Category {
  id: number
  name: string
  slug: string
  thumb: string

  constructor(category: object) {
    this.id = category.id
    this.name = category.attributes.name
    this.slug = category.attributes.slug
    this.thumb = category.attributes.thumbnail_url
  }
}

export { Category }
