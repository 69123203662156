import type { DomainConfiguration } from '@/router/types/DomainConfiguration'
import { getDomainConfig } from '@/config/domains/domainConfig'

export const getConfigurationForCountry = (country: string): DomainConfiguration => {
  const domainConfig = getDomainConfig()
  for (const domain in domainConfig) {
    for (const config of domainConfig[domain].configurations) {
      if (config.country === country) {
        return config
      }
    }
  }

  throw new Error(`"${country}" is not supported`)
}