import { type Ref } from 'vue'
import { useLocalizationStore } from '@/stores/useLocalizationStore'
import { storeToRefs } from 'pinia'

/**
 * Returns the reactive value for current country
 * The value is resolved during route resolution and should only be `null` on unresolved routes (404)
 */
export const getCountry = (): Ref<string> => {
  const { currentCountry } = storeToRefs(useLocalizationStore())
  return currentCountry
}