import { createApp } from './main'
import { createHead } from '@unhead/vue'
import { SentryFactory } from '@/factories/SentryFactory'

// Create the app
createApp().then(({ app, router, pinia }) => {
  SentryFactory.create(app, router, __CAPACITOR__ ? 'capacitor' : 'csr')

  const clientHead = createHead()
  app.use(clientHead)

if (window.__INITIAL_STATE__) {
  pinia.state.value.ssr_establishment = window.__INITIAL_STATE__.ssr_establishment
  pinia.state.value.ssr_marketing_page = window.__INITIAL_STATE__.ssr_marketing_page
  pinia.state.value.ssr_marketplace = window.__INITIAL_STATE__.ssr_marketplace
}

  // Wait for the router to be ready (for async components or routes)
  router.isReady().then(() => {
    // Mount the app
    app.mount('#app')
  })
})
