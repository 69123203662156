import type { App } from 'vue'
import { init as initSentryVue, replayIntegration as replayIntegrationVue } from '@sentry/vue'
import * as SentryCapacitor from '@sentry/capacitor'
import type { Client } from '@sentry/core'
import type { Router } from 'vue-router'
import { version } from '../../package.json'

class SentryFactory {
  static create(app: App, router: Router, environment: 'ssr' | 'csr' | 'capacitor'): Client | undefined {
    const nodeEnv = process.env.NODE_ENV || 'development'

    if (environment === 'ssr') {
      return initSentryVue({
        app,
        dsn: 'https://7cc40fd88cf288f83ecf8b0c66bf4087@o576069.ingest.us.sentry.io/4508811142168576',
        integrations: [],
        environment: nodeEnv,
        release: `app-ssr-${version}`,
        dist: 'client',
        autoSessionTracking: false, // Disable session tracking in SSR
        replaysSessionSampleRate: 0.0,
        tracesSampleRate: 0.0,
      }) as Client
    }

    if (environment === 'csr') {
      return initSentryVue({
        app,
        dsn: 'https://88c9bab642dbe948347bc3efb822aa56@o576069.ingest.us.sentry.io/4508811156520960',
        integrations: [
          // browserTracingIntegrationVue({router}),
          replayIntegrationVue({
            maskAllText: false,
            maskAllInputs: false,
          }),
        ],
        environment: nodeEnv,
        release: `app-csr-${version}`,
        dist: 'server',
        tracesSampleRate: 0.0,
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost'],
        // Session Replay
        replaysSessionSampleRate: 0.0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      }) as Client
    }

    if (environment === 'capacitor') {
      SentryCapacitor.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        enableAutoSessionTracking: true,
        sessionTrackingIntervalMillis: 30000,
        environment: nodeEnv,
        release: import.meta.env.VITE_RELEASE,
        dist: 'capacitor',
        tracesSampleRate: 0.0,
      })

      // Then initialize Vue with Capacitor
      return initSentryVue({
        app,
        dsn: 'https://8f49116366771f9527ff17f71f1c246e@o576069.ingest.us.sentry.io/4508811160190976',
        integrations: [
          // browserTracingIntegrationVue({router}),
          replayIntegrationVue(),
        ],
        environment: 'mobile',
        release: `app-capacitor-${version}`,
        dist: 'capacitor',
        // Session Replay
        replaysSessionSampleRate: 0.0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        tracesSampleRate: 0.0,
      }) as Client
    }

    throw new Error(`Invalid environment: "${environment}"`)
  }
}

export { SentryFactory }