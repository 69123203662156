import { useMarketplaceStore } from '@/stores/ssr/useMarketplaceStore'
import type { RouterScrollBehavior } from 'vue-router'

export const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  if (import.meta.env.SSR) {
    return
  }

  const marketplaceStore = useMarketplaceStore()

  const toMarketplace = (to.name as string)?.endsWith('marketplace')
  const fromEstablishment = (from.name as string)?.endsWith('establishment')

  if (toMarketplace && fromEstablishment && marketplaceStore.currentPage > 1) {
    return { left: 0, top: marketplaceStore.scrollPosition }
  }

  if (to.name === from.name) {
    return
  }

  if (to.hash) {
    return { el: to.hash, behavior: 'smooth' }
  }

  if (savedPosition) {
    return savedPosition
  }

  return { top: 0 }
}