import { type App, type Plugin } from 'vue'
import { Capacitor } from '@capacitor/core'
import { Platform } from '@/enums/Platform'

export const PlatformPlugin: Plugin = {
  install(app: App) {
    if (__CAPACITOR__) {
      const platform = Capacitor.getPlatform()

      if (platform === 'android') {
        app.provide('platform', Platform.ANDROID)
      } else if (platform === 'ios') {
        app.provide('platform', Platform.IOS)
      } else {
        app.provide('platform', Platform.WEB)
      }
    } else {
      app.provide('platform', Platform.WEB)
    }
  },
}
