import axios, { type AxiosInstance, type InternalAxiosRequestConfig, isAxiosError } from 'axios'
import { version } from '../../package.json'
import { useI18n } from '@/composables/I18n'
import { useAuthStore } from '@/stores/useAuthStore'
import { useModalStore } from '@/stores/useModalStore'
import { useLocalizationStore } from '@/stores/useLocalizationStore'
import { useTrackingStore } from '@/stores/useTrackingStore'
import { useUserStore } from '@/stores/useUserStore'
import { type Router } from 'vue-router'

let http: AxiosInstance | null = null

export const useHttp = (): AxiosInstance => {
  if (http === null) {
    throw new Error('no http')
  }

  return http
}

export const createHttp = (router: Router) => {
  const authStore = useAuthStore()
  const userStore = useUserStore()
  const {show} = useModalStore()
  const trackingStore = useTrackingStore()
  const instance = axios.create({
    baseURL: `${import.meta.env.VITE_BACKEND_URL}${import.meta.env.VITE_API_PATH}`,
  })

  instance.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    const {getCurrentLocale, getCurrentCountry} = useLocalizationStore()

    request.headers.set('Access-Control-Allow-Origin', '*')
    request.headers.set('Accept', 'application/json')
    request.headers.set('Accept-Language', getCurrentLocale())
    request.headers.set('X-Country', getCurrentCountry())
    request.headers.set('App-Version', version)

    if (authStore.isAuthenticated) {
      request.headers.set('Authorization', `Bearer ${authStore.accessToken}`)
    }

    if (trackingStore.deviceToken && trackingStore.sessionToken) {
      request.headers.set('X-Tracking-Device-Token', trackingStore.deviceToken)
      request.headers.set('X-Tracking-Session-Token', trackingStore.sessionToken)
    }

    return request
  }, async (error): Promise<any> => {

    const i18n = useI18n()
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    show({
      message: i18n.global.t('errors.connection-lost'),
      closable: false,
      data: error,
    })

    return Promise.reject(error)
  })

  instance.interceptors.response.use(null, async (error): Promise<any> => {
    if (!isAxiosError(error)) {
      return Promise.reject(error)
    }

    if (error.response?.status === 400) {
      if (error.response.data?.data?.code === 'UPGRADE_REQUIRED') {

        const i18n = useI18n()

        show({
          message: i18n.global.t('errors.update-required'),
          closable: false,
        })
      }
    }

    if (error.response?.status === 401) {
      authStore.setToken(null)
      userStore.clearUser()

      await router.push({name: 'login'})

      return
    }

    if (error.response?.status === 404) {
      if (import.meta.env.SSR) {
        return Promise.reject(error)
      }
    }

    if (error.response?.status >= 500) {
      const i18n = useI18n()

      show({
        message: i18n.global.t('errors.error-from-api'),
        closable: true,
        data: error,
      })
    }

    return Promise.reject(error)
  })

  http = instance
}